::selection {
  color: white;
  background: #5dbcd2; /* WebKit/Blink Browsers */
}
::-moz-selection {
  color: white;
  background: #5dbcd2; /* Gecko Browsers */
}

.ip-header .ip-loader svg path.ip-loader-circle{
  stroke: #5dbcd2;
}

.color-overlay {
    
}

a:hover {
    color: #5dbcd2;
}

a:active {
    color: #5dbcd2;
}

a:focus{
  color: #5dbcd2;
}


.sticky-navigation{

    /*border-bottom: solid 1px #5dbcd2 ;*/
}

.sticky-navigation .main-navigation .current a{
    color: #5dbcd2;
}

.sticky-navigation .main-navigation li a:hover{
  color: #5dbcd2;
  background: none;
}

.sticky-navigation .main-navigation li a:active{
  color: #5dbcd2;
  background: none;
}

.sticky-navigation .main-navigation li a:focus{
  color: #5dbcd2;
  background: none;
}

.navbar-toggle .icon-bar{
  background-color: #5dbcd2;
}


.serv-item .active-item .service-icon{
  color: #5dbcd2;
}


 .work-process .itemz .inner-content > i{
  background-color: #5dbcd2;
 }

 .grid figcaption a{
  background: #5dbcd2;
}

.grid figcaption{
  color: #5dbcd2;
}

.input__label--hoshi-color-2::after {
  border-color: #5dbcd2;
}

.scroll-up a:hover,
.scroll-up a:focus{
  background-color: #5dbcd2;
  opacity: 0.7;
}

 .pf-filter li.active{
  color: #5dbcd2;
}

.portfolio-hover{
    background-color: rgba(93, 188, 210, .8);
}

.portfolio-project-info li a:hover {
    color: #5dbcd2;
}

.testimonials .owl-theme .owl-controls .owl-page span {
    background: #5dbcd2;
    border-radius: 20%;
    max-height: 2px;
}

.clients .owl-theme .owl-controls .owl-page span {
    background: #5dbcd2;
    border-radius: 20%;
    max-height: 2px;
}



.team .owl-theme .owl-controls .owl-page span {
    background: #5dbcd2;
}

.special-package{
  color: #5dbcd2;
}

.special-package .price h2,
.special-package .price h2 span,
.special-package .price p 
{
  color: #5dbcd2;
}

.special-package .hvr-bounce-to-right:before{
  background: #5dbcd2;
}

.special-package .single-package{
  border: 1px solid #5dbcd2;
}

.special-package .btn-price{
  color: #5dbcd2;
  border-color: #5dbcd2;
}

.special-package .btn-price:hover{
  color: #fff;
}


.btn-lines .line-top,
.btn-lines .line-bottom,
.btn-lines .line-left,
.btn-lines .line-right {
  background: #5dbcd2;
}

.funfactor .icon-container i {
    color: #5dbcd2;
}

.blog-title :hover{
    color: #5dbcd2;
}

.blog-post-title :hover {
    color: #5dbcd2;
}

.author a :hover {
  color: #5dbcd2;
  
 }

 .read-more :hover{
    color: #5dbcd2;
 }

.blog-list-item-detail a:hover{
  color: #5dbcd2;
}

.blog-item-detail a:hover{
  color: #5dbcd2;
}

.input-field:active{
  border: 1px solid  #5dbcd2;
  border-radius: 5px;
}

.input-field:focus {
  border-color: #5dbcd2;
}

.clearlist li a:hover  {
    color: #5dbcd2;
}

.tags a:hover {
   color: #5dbcd2;
  border: 1px solid #5dbcd2;
}

.article-nav a i:hover{
  color: #5dbcd2;
}

/**/

.standard-button {
    background: #B58C82;
}

.standard-button2 {
    background:  #5dbcd2;
}

.standard-button2:hover {
    background: #fff;
}

.btn-primary:focus, 
.btn-primary:active, 
.btn-primary.active, 
.open .dropdown-toggle.btn-primary {
    background: #005885;
    border-color: inherit;
}

.colored-line,
.colored-line-left {
    background:  #5dbcd2;
}

.main-color {
    color:  #5dbcd2;
}

.theme-color {
    color:  #5dbcd2;
}

.theme-color-bg {
    background-color:  #5dbcd2;
}

.color-bg {
	background: rgb(127, 111, 91);
	color: #FFFFFF;
}

.contact .contact-button {
    border: 2px solid #656565;
    color: #958257;
}

.contact .contact-button:hover {
    background:  #5dbcd2;
}

.contact .contact-box .input-box:active,
.contact .contact-box .textarea-box:active,
.contact .contact-box .input-box:focus,
.contact .contact-box .textarea-box:focus {
    border: 1px solid  #5dbcd2;
    color: #111;
}

.social-icons li a:hover {
    color:  #5dbcd2;
}

.comment-reply .contact-box .input-box:active,
.comment-reply .contact-box .textarea-box:active,
.comment-reply .contact-box .input-box:focus,
.comment-reply .contact-box .textarea-box:focus {
    border: 1px solid  #5dbcd2;
    color: #111;
}

.contact  .contact-address i{
  color: #5dbcd2;
}

.nav-transparent{
  border-bottom: solid 1px transparent;
}


.list-tweets li a:hover{
  color: #5dbcd2;
}